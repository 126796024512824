import { API_PATH } from "../../../commons/constants";
import {
  changeFormData,
  changeLevelData,
  reliabilityAcquired
} from "../../home/actions";
import fetchApi from "../../../commons/fetch-api";
import { changeMessageDelay } from "../../layout/actions";
import { persistNewReliability } from "../../../commons/reliability-store/actions";

const SLEEP_TIMEOUT_IN_MS = 10000;
function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export const createFacialBiometricsQrCode = (
  reliabilityId,
  currentPath,
  history,
  reliabilityDispatch
) => async (dispatch, state) => {
  const pathRequest = API_PATH.endpoints.postCreateFacialBiometricsQrCode
    .replace(":accountId", state.logged_user.id)
    .replace(":reliabilityId", reliabilityId);

  const biovalid = await fetchApi(pathRequest, { method: "POST" });
  if (biovalid) {
    dispatch(changeFormData({ biovalid }));

    const url = API_PATH.endpoints.putValidateFacialBiometricsQrCode
      .replace(":accountId", state.logged_user.id)
      .replace(":biovalidPin", biovalid.pin);

    var res;
    var callStoped = false;
    for (var i = 0; i < 15 && !callStoped; i++) {
      await sleep(SLEEP_TIMEOUT_IN_MS).then(() => {});
      res = await fetchApi(url, { method: "PUT" }, false, currentPath);
      if (!res) {
        continue;
      }
      if (res && res.stopCall) {
        i = 10;
        callStoped = true;
        return;
      }
      i = 10;
      if (res?.reliabilityAcquired) {
        changeLevelData({
          ...state.levelData,
          up: res?.hasLevelChanged,
          newReliability: res?.reliabilityId,
          level: res?.userLevel
        });

        if (res?.hasLevelChanged) {
          reliabilityDispatch(persistNewReliability(res?.reliabilityId));
          dispatch(reliabilityAcquired(res.hasLevelChanged));
        }

        history.push("/");
      } else {
        biovalid.reliabilityAcquired = res.reliabilityAcquired;
        biovalid.biometricsValidationSource = res.biometricsValidationSource;
        biovalid.biovalidValidated = true;
        dispatch(changeFormData({ biovalid }));
      }
    }
    if (!biovalid.biovalidValidated && !callStoped) {
      dispatch(
        changeFormData({
          biovalid: {
            biovalidValidated: false,
            reliabilityAcquired: false,
            biometricsValidationSource: undefined
          }
        })
      );
      dispatch(
        changeMessageDelay({
          type: "ERROR",
          text: "Rosto não reconhecido. Por favor, tente novamente."
        })
      );
    }
  }
};

export const validateLastDriverLicenseDate = (
  date,
  validationSource,
  history,
  reliabilityDispatch
) => async (dispatch, state) => {
  let formatted_date = window.formatDate(date);
  const pathRequest = API_PATH.endpoints.putValidateDriverLicenseDate.replace(
    ":accountId",
    state.logged_user.id
  );

  await fetchApi(pathRequest, {
    method: "PUT",
    body: JSON.stringify({
      biometricsValidationSource: validationSource,
      lastDriverLicenseIssueDate: formatted_date
    })
  }).then(res => {
    changeLevelData({
      ...state.levelData,
      up: res?.hasLevelChanged,
      newReliability: res?.reliabilityId,
      level: res?.userLevel
    });

    if (res?.hasLevelChanged) {
      reliabilityDispatch(persistNewReliability(res?.reliabilityId));
      dispatch(reliabilityAcquired(res.hasLevelChanged));
    }

    history.push("/");
  });
};
