import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useAppState } from "../AppContext";
import { dropdownMenuAvatar, dropdownMenuServico } from "./actions";
import { logoutUser } from "../../commons/logged-user/actions";

function useOutsideAlerter(ref) {
  const [state, dispatch] = useAppState();
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(dropdownMenuAvatar(false));
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, ref]);
}

function Avatar(props) {
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  const [state, dispatch] = useAppState();

  const dropdown = () => {
    dispatch(dropdownMenuAvatar(!state.layout.avatar));
    dispatch(dropdownMenuServico(false));
  };

  const [{ reliabilities }] = useAppState();
  const lvls = reliabilities.reliabilities;
  const lvlsId = [];
  let maxLevel = 0;

  return (
    <div ref={wrapperRef} className="dropdown">
      {/* Obtendo o maior nível */}
      {lvls &&
        lvls
          .filter(l1 => l1.anyAcquired)
          .map(l2 => {
            lvlsId.push(parseInt(l2.level.id));
            maxLevel = Math.max(...lvlsId);
          })}

      <button id="btnMenu" onClick={() => dropdown()}>
        <img
          alt="Foto"
          className="avatar-pic"
          src={`${state.logged_user.photoBase64}`}
          onError={e => {
            e.target.src = `${process.env.REACT_APP_URL_ASSETS}/images/user-avatar.png`;
          }}
        />
      </button>
      <div className={`dropdown-content ${state.layout.avatar ? "show" : ""}`}>
        <a
          className="link-nivel"
          href={`${process.env.REACT_APP_CONTAS_BFF_URL}`}
        >
          <p>
            Olá,{" "}
            <strong className="caixa-alta">
              {state.logged_user.social_name || state.logged_user.name}
            </strong>
          </p>
          <p className="caixa-alta">
            A sua conta é nível{" "}
            <strong>
              {maxLevel === 1 && "Bronze"}
              {maxLevel === 2 && "Prata"}
              {maxLevel === 3 && "Ouro"}
            </strong>
          </p>
          {maxLevel === 1 && (
            <img
              id="imglevel"
              alt="Nível"
              src={`${process.env.REACT_APP_URL_ASSETS}/images/bronze_horizontal.png`}
            />
          )}
          {maxLevel === 2 && (
            <img
              id="imglevel"
              alt="Nível"
              src={`${process.env.REACT_APP_URL_ASSETS}/images/silver_horizontal.png`}
            />
          )}
          {maxLevel === 3 && (
            <img
              id="imglevel"
              alt="Nível"
              src={`${process.env.REACT_APP_URL_ASSETS}/images/gold_horizontal.png`}
            />
          )}
        </a>

        <br />
        <span>Minha conta</span>
        <hr />

        <ul id="avatarmenu">
          <li>
            <a
              href={`${process.env.REACT_APP_CONTAS_BFF_URL}/alteracao_cadastro`}
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Dados Pessoais
            </a>
          </li>
          <li>
            <a
              href={`${process.env.REACT_APP_CONTAS_BFF_URL}/seguranca`}
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Segurança da Conta
            </a>
          </li>
          <li>
            <a
              href={`${process.env.REACT_APP_CONTAS_BFF_URL}/privacidade`}
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Privacidade
            </a>
          </li>
          {state.logged_user.cnpj && (
            <li>
              <a
                href={`${process.env.REACT_APP_CONTAS_BFF_URL}/empresas`}
                onClick={() => dispatch(dropdownMenuAvatar(false))}
              >
                Vincular Empresas via e-CNPJ
              </a>
            </li>
          )}
        </ul>

        <span>Serviços</span>
        <hr />

        <ul id="avatarmenu">
          <li>
            <a
              href={`${process.env.REACT_APP_PORTAL_LOGADO_URL}/carteira`}
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Carteira de documentos
            </a>
          </li>
          <li>
            <a
              href={`${process.env.REACT_APP_PORTAL_LOGADO_URL}/certidoes`}
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Baixar certidões
            </a>
          </li>
          <li>
            <a
              href="https://assinador.iti.br/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Assinar documentos digitalmente
            </a>
          </li>
          {/* <li>
            <a
              href={`${process.env.REACT_APP_PORTAL_LOGADO_URL}`}
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Serviços solicitados
            </a>
          </li> */}
          <li>
            <a
              href={`${process.env.REACT_APP_PORTAL_LOGADO_URL}/notificacoes`}
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Notificações
            </a>
          </li>
          <li>
            <a
              href={`${process.env.REACT_APP_PORTAL_LOGADO_URL}/consultarservicossolicitados`}
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Consultar serviços solicitados
            </a>
          </li>
          <li>
            <a
              href="https://www.gov.br/governodigital/pt-br/conta-gov-br/ajuda-da-conta-gov.br"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Ajuda da conta gov.br
            </a>
          </li>
        </ul>

        <button
          className="btn-secondary"
          id="btnLogout"
          onClick={() => dispatch(logoutUser())}
        >
          Sair da conta
        </button>
      </div>
    </div>
  );
}

export default Avatar;
